<template>
<div>
  <custom-breadcrumb
      :pageTitle="$t('Roles & Permissions')"
      :breadcrumb="[
        {
          text: $t('Roles & Permissions'),
          active: true,
        },
      ]"
  ></custom-breadcrumb>
  <b-card>
    <b-tabs>
      <b-tab :title="$t('Roles')">
        <roles-tab :roles="roles"
        @set-data="setData"/>
      </b-tab>
      <b-tab :title="$t('Permissions')">
        <div class="p-1">
          <div class="row">
            <div class="col-sm-6">
              <b-form-select
                v-model="selected"
                :options="options"
              />
            </div>
          </div>
        </div>
        <b-table
          sticky-header="true"
          :no-border-collapse="true"
          striped
          :items="roleSelected"
          class="mb-0"
          v-if="selected"
          style="max-height: 50vh;"
        >

          <template #cell(model)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-form-checkbox
              @change="toggleCheckbox(data)"
              :checked="data.value"
            />
          </template>

        </b-table>
        
        <b-card-footer>
          <b-button
            v-if="$can('update', 'Roles')"
            style="float: right;"
            variant="primary"
            type="button"
            @click="save"
          >
            {{ $t('forms.save_changes') }}
          </b-button>
        </b-card-footer>
      </b-tab>
      <b-tab title="Models">
        <models-tab 
          :models="models"
          :models_options="models_options"
          @set-data="setData"/>
      </b-tab>
    </b-tabs>
  </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BTabs, BTab,
  BCard, BTable, BButton, BCardBody, BCardFooter, BCardTitle, BCardHeader, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import { BFormSelect, BCardText } from 'bootstrap-vue'
import RolesTab from './components/roles/RolesTab.vue'
import ModelsTab from './components/models/ModelsTab.vue'
export default {
  components: {
    BTabs, BTab,
    BFormInput, BRow, BCol, BFormGroup,
    BCard, BTable, BButton, BCardBody, BCardFooter, BCardTitle, BCardHeader, BCardSubTitle, BFormCheckbox,BFormSelect, BCardText,

    RolesTab,
    ModelsTab,
    CustomBreadcrumb
  },
  data() {
    return {
      selected: null,
      model_selected:null, 
      roles: [],
      options: [
        { value: null, text: 'Please select a role' },
      ],

      permissionData: {},
      
      models_options:[
        { value: null, text: 'Please select a model' },
      ],
      models:[],
    }
  },
  computed: {
    roleSelected(){
      return this.permissionData[this.selected]
    }
  },
  methods:{
    toggleCheckbox(data){
      let newPermissionData = {...this.permissionData}
      this.permissionData[this.selected].map((x,idx) => {
        if(x.model == data.item.model){
          newPermissionData[this.selected][idx][data.field.key] = !newPermissionData[this.selected][idx][data.field.key]
        }
      })

      this.permissionData = newPermissionData
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    setData(response){
      let roles = response.data.roles;
      this.roles = roles;
      this.models = response.data.subjects;
      let models = response.data.subjects;
      
      let newOptions = [
        { value: null, text: 'Please select a role' }
      ]
      roles.forEach(rol => {
        let capName = rol.name.charAt(0).toUpperCase() + rol.name.slice(1);

        newOptions.push(
          { 'value': rol.id, 'text': capName }
        ) 
      });
      this.options = newOptions;

      let newModelsOptions = [
        { value: null, text: 'Please select a model' }
      ]
      models.forEach(model => {
        let capName = model.name.charAt(0).toUpperCase() + model.name.slice(1);

        newModelsOptions.push(
          { 'value': model.id, 'text': capName }
        ) 
      });
      this.models_options = newModelsOptions;

      this.permissionData = response.data.permissions;
    },
    save(){
      let data = {
        'abilities': this.permissionData
      }
      this.$http.post('saveAbilitiesRole', data).then( response =>{
        console.log(response)
        this.setData(response);

        this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));

      }).catch(error => {
        console.log("Err -> ", error)
      })
    },
    getData(){
      this.$http.post('roles', {}).then( response =>{
        console.log(response)
        this.setData(response);
      }).catch(error => {
        console.log("Err -> ", error)
      })
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>